/* @flow */

import type { DeclensionRuleSetT } from '../inclineRules';

const lastname: DeclensionRuleSetT = {
  exceptions: [
    {
      gender: 'androgynous',
      test: [
        'бонч',
        'абдул',
        'белиц',
        'гасан',
        'дюссар',
        'дюмон',
        'книппер',
        'корвин',
        'ван',
        'шолом',
        'тер',
        'призван',
        'мелик',
        'вар',
        'фон',
      ],
      mods: ['.', '.', '.', '.', '.'],
      tags: ['first_word'],
    },
    {
      gender: 'androgynous',
      test: [
        'дюма',
        'тома',
        'дега',
        'люка',
        'ферма',
        'гамарра',
        'петипа',
        'шандра',
        'скаля',
        'каруана',
      ],
      mods: ['.', '.', '.', '.', '.'],
    },
    {
      gender: 'androgynous',
      test: [
        'гусь',
        'ремень',
        'камень',
        'онук',
        'богода',
        'нечипас',
        'долгопалец',
        'маненок',
        'рева',
        'кива',
      ],
      mods: ['.', '.', '.', '.', '.'],
    },
    {
      gender: 'androgynous',
      test: ['вий', 'сой', 'цой', 'хой'],
      mods: ['-я', '-ю', '-я', '-ем', '-е'],
    },
  ],
  suffixes: [
    {
      gender: 'female',
      test: [
        'б',
        'в',
        'г',
        'д',
        'ж',
        'з',
        'й',
        'к',
        'л',
        'м',
        'н',
        'п',
        'р',
        'с',
        'т',
        'ф',
        'х',
        'ц',
        'ч',
        'ш',
        'щ',
        'ъ',
        'ь',
      ],
      mods: ['.', '.', '.', '.', '.'],
    },
    {
      gender: 'androgynous',
      test: ['орота'],
      mods: ['.', '.', '.', '.', '.'],
    },
    {
      gender: 'female',
      test: ['ска', 'цка'],
      mods: ['-ой', '-ой', '-ую', '-ой', '-ой'],
    },
    {
      gender: 'female',
      test: ['цкая', 'ская', 'ная', 'ая'],
      mods: ['--ой', '--ой', '--ую', '--ой', '--ой'],
    },
    {
      gender: 'female',
      test: ['яя'],
      mods: ['--ей', '--ей', '--юю', '--ей', '--ей'],
    },
    {
      gender: 'male',
      test: ['иной', 'уй'],
      mods: ['-я', '-ю', '-я', '-ем', '-е'],
    },
    {
      gender: 'androgynous',
      test: ['ца'],
      mods: ['-ы', '-е', '-у', '-ей', '-е'],
    },
    {
      gender: 'male',
      test: ['рих'],
      mods: ['а', 'у', 'а', 'ом', 'е'],
    },
    {
      gender: 'androgynous',
      test: ['ия'],
      mods: ['-и', '-и', '-ю', '-ей', '-и'],
    },
    {
      gender: 'androgynous',
      test: ['иа', 'аа', 'оа', 'уа', 'ыа', 'еа', 'юа', 'эа'],
      mods: ['.', '.', '.', '.', '.'],
    },
    {
      gender: 'androgynous',
      test: ['о', 'е', 'э', 'и', 'ы', 'у', 'ю'],
      mods: ['.', '.', '.', '.', '.'],
    },
    {
      gender: 'male',
      test: ['их', 'ых'],
      mods: ['.', '.', '.', '.', '.'],
    },
    {
      gender: 'female',
      test: ['ова', 'ева', 'на', 'ёва'],
      mods: ['-ой', '-ой', '-у', '-ой', '-ой'],
    },
    {
      gender: 'androgynous',
      test: ['га', 'ка', 'ха', 'ча', 'ща', 'жа', 'ша'],
      mods: ['-и', '-е', '-у', '-ой', '-е'],
    },
    {
      gender: 'androgynous',
      test: ['а'],
      mods: ['-ы', '-е', '-у', '-ой', '-е'],
    },
    {
      gender: 'male',
      test: ['ь'],
      mods: ['-я', '-ю', '-я', '-ем', '-е'],
    },
    {
      gender: 'androgynous',
      test: ['я'],
      mods: ['-и', '-е', '-ю', '-ей', '-е'],
    },
    {
      gender: 'male',
      test: ['обей'],
      mods: ['--ья', '--ью', '--ья', '--ьем', '--ье'],
    },
    {
      gender: 'male',
      test: ['ей'],
      mods: ['-я', '-ю', '-я', '-ем', '-е'],
    },
    {
      gender: 'male',
      test: ['ян', 'ан', 'йн'],
      mods: ['а', 'у', 'а', 'ом', 'е'],
    },
    {
      gender: 'male',
      test: ['ынец', 'овец'],
      mods: ['--ца', '--цу', '--ца', '--цом', '--це'],
    },
    {
      gender: 'male',
      test: ['нец', 'обец'],
      mods: ['--ца', '--цу', '--ца', '--цем', '--це'],
    },
    {
      gender: 'male',
      test: ['ай'],
      mods: ['-я', '-ю', '-я', '-ем', '-е'],
    },
    {
      gender: 'male',
      test: ['гой', 'кой'],
      mods: ['-го', '-му', '-го', '--им', '-м'],
    },
    {
      gender: 'male',
      test: ['ой'],
      mods: ['-го', '-му', '-го', '--ым', '-м'],
    },
    {
      gender: 'male',
      test: ['ах', 'ив', 'шток'],
      mods: ['а', 'у', 'а', 'ом', 'е'],
    },
    {
      gender: 'male',
      test: ['ший', 'щий', 'жий', 'ний'],
      mods: ['--его', '--ему', '--его', '-м', '--ем'],
    },
    {
      gender: 'male',
      test: ['ый', 'кий', 'хий'],
      mods: ['--ого', '--ому', '--ого', '-м', '--ом'],
    },
    {
      gender: 'male',
      test: ['ий'],
      mods: ['-я', '-ю', '-я', '-ем', '-и'],
    },
    {
      gender: 'male',
      test: ['ок'],
      mods: ['--ка', '--ку', '--ка', '--ком', '--ке'],
    },
    {
      gender: 'male',
      test: ['иец', 'еец'],
      mods: ['--йца', '--йцу', '--йца', '--йцом', '--йце'],
    },
    {
      gender: 'male',
      test: ['ец'],
      mods: ['--ца', '--цу', '--ца', '--цом', '--це'],
    },
    {
      gender: 'male',
      test: ['ц', 'ч', 'ш', 'щ'],
      mods: ['а', 'у', 'а', 'ем', 'е'],
    },
    {
      gender: 'male',
      test: [
        'ен',
        'нн',
        'он',
        'ун',
        'б',
        'г',
        'д',
        'ж',
        'з',
        'к',
        'л',
        'м',
        'п',
        'р',
        'с',
        'т',
        'ф',
        'х',
      ],
      mods: ['а', 'у', 'а', 'ом', 'е'],
    },
    {
      gender: 'male',
      test: ['в', 'н'],
      mods: ['а', 'у', 'а', 'ым', 'е'],
    },
  ],
};

export default lastname;
